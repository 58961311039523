
import { Component, Vue } from 'vue-property-decorator'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Label/Wert-Paar mit einzustellender Farbe für <v-select>.
 */
interface LabelColor {
  /**
   * Label des Eintrags.
   */
  label: string

  /**
   * Einstellbare Farbe.
   */
  value: Color
}

/**
 * Einstellbare Farben.
 */
enum Color {
  Primary = 'primary',
  Secondary = 'secondary',
  Accent = 'accent',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

/**
 * Dashboard-View.
 */
@Component({
  components: {
    BaseFrame
  }
})
/**
 *
 */
export default class ColorSettings extends Vue {
  /**
   * Gibt an, ob die Farbauswahl gerade geöffnet ist.
   */
  public pickerIsOpen = false

  /**
   * Im Dropdown ausgewählte, einzustellende Farbe.
   */
  public selectedColor: Color = Color.Primary

  /**
   * Verfügbare, einstellbare Farben.
   */
  public colorItems: LabelColor[] = [
    {
      label: 'colorSettings.color.primary',
      value: Color.Primary
    },
    {
      label: 'colorSettings.color.secondary',
      value: Color.Secondary
    },
    {
      label: 'colorSettings.color.accent',
      value: Color.Accent
    }
    // {
    //   label: 'Fehler',
    //   value: Color.Error
    // },
    // {
    //   label: 'Info',
    //   value: Color.Info
    // },
    // {
    //   label: 'Erfolg',
    //   value: Color.Success
    // },
    // {
    //   label: 'Warnung',
    //   value: Color.Warning
    // }
  ]

  /**
   * Wert der aktuell einzustellenden Farbe.
   *
   * @returns Gibt den Farbwert in HEX zurück.
   */
  public get color(): string | VuetifyThemeItem {
    const darkMode = this.$vuetify.theme.dark
    const { light, dark } = this.$vuetify.theme.themes

    if (darkMode) {
      if (this.selectedColor === Color.Primary) {
        return dark.primary
      }

      if (this.selectedColor === Color.Secondary) {
        return dark.secondary
      }

      if (this.selectedColor === Color.Accent) {
        return dark.accent
      }

      if (this.selectedColor === Color.Error) {
        return dark.error
      }

      if (this.selectedColor === Color.Info) {
        return dark.info
      }

      if (this.selectedColor === Color.Success) {
        return dark.success
      }

      if (this.selectedColor === Color.Warning) {
        return dark.warning
      }
    } else {
      if (this.selectedColor === Color.Primary) {
        return light.primary
      }

      if (this.selectedColor === Color.Secondary) {
        return light.secondary
      }

      if (this.selectedColor === Color.Accent) {
        return light.accent
      }

      if (this.selectedColor === Color.Error) {
        return light.error
      }

      if (this.selectedColor === Color.Info) {
        return light.info
      }

      if (this.selectedColor === Color.Success) {
        return light.success
      }

      if (this.selectedColor === Color.Warning) {
        return light.warning
      }
    }

    return ''

    // throw new Error('Invalid color selected.')
  }

  /**
   * Setzt den Wert der aktuell einzustellenden Farbe.
   *
   * @param value - Wert als HEX-String (#AABBCC).
   */
  public set color(value: string | VuetifyThemeItem) {
    const darkMode = this.$vuetify.theme.dark
    const { light, dark } = this.$vuetify.theme.themes

    if (darkMode) {
      if (this.selectedColor === Color.Primary) {
        dark.primary = value
      }

      if (this.selectedColor === Color.Secondary) {
        dark.secondary = value
      }

      if (this.selectedColor === Color.Accent) {
        dark.accent = value
      }

      if (this.selectedColor === Color.Error) {
        dark.error = value
      }

      if (this.selectedColor === Color.Info) {
        dark.info = value
      }

      if (this.selectedColor === Color.Success) {
        dark.success = value
      }

      if (this.selectedColor === Color.Warning) {
        dark.warning = value
      }
    } else {
      if (this.selectedColor === Color.Primary) {
        light.primary = value
      }

      if (this.selectedColor === Color.Secondary) {
        light.secondary = value
      }

      if (this.selectedColor === Color.Accent) {
        light.accent = value
      }

      if (this.selectedColor === Color.Error) {
        light.error = value
      }

      if (this.selectedColor === Color.Info) {
        light.info = value
      }

      if (this.selectedColor === Color.Success) {
        light.success = value
      }

      if (this.selectedColor === Color.Warning) {
        light.warning = value
      }
    }
  }

  /**
   * Setzt die zu bearbeitende Farbe und öffnet den Farbauswahl-Dialog.
   *
   * @param color - Zu bearbeitende Farbe.
   */
  public edit(color: Color): void {
    this.selectedColor = color
    this.pickerIsOpen = true
  }
}
